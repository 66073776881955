import React from 'react';
import ChooseGameCard from './ChooseGameCard';

function ChooseGame(props) {
  return (
    <section class="pt-20 pb-24 2xl:py-40 bg-gray-800">
      <div class="container mx-auto px-4">
        <div class="mb-10 text-center">
          <h2 class="mb-8  text-5xl text-white font-bold font-heading">What do you want your castle to do?</h2>
          <p class="text-lg text-gray-200">Pick a game module for your castle</p>
        </div>
        <div class="max-w-6xl mx-auto">
          <div class="flex flex-wrap items-center -mx-3">
            {props.games.map((object, i) => (
              <ChooseGameCard key={i} game={object} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChooseGame;
