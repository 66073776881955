import React from 'react';
import { RiArrowRightUpLine } from 'react-icons/ri';
import moonCircle from '../img/moon-circle.png';
import nft0 from '../img/nft0.png';
import nft1 from '../img/nft1.png';
import nft2 from '../img/nft2.png';
import nft3 from '../img/nft3.png';
import nft4 from '../img/nft4.png';
import nft5 from '../img/nft5.png';
import HomeShoutout from '../components/home/HomeShoutout';
import { Link } from 'react-router-dom';

function Home(props) {
  console.log('loading home');
  return (
    <div>
      <section className="pb-20 bg-gray-800">
        <div className="relative container px-4 pt-12 md:pt-20 mx-auto">
          <div className="flex flex-wrap items-center -mx-4 mb-20 2xl:mb-14">
            <div className="w-full lg:w-1/2 px-4 mb-16 lg:mb-0">
              <h2 className="max-w-2xl mt-12 mb-12 text-6xl 2xl:text-8xl text-white font-bold font-heading">
                There are two moons amongst the stars
              </h2>
              <p className="mb-12 lg:mb-16 2xl:mb-24 text-xl text-gray-100">and castles in the sky</p>
              <a
                className="inline-block px-12 py-5 text-lg text-white font-bold bg-blue-500 hover:bg-blue-600 rounded-full transition duration-200"
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.twomoons.xyz"
              >
                View the Whitepaper <RiArrowRightUpLine className="p-0.5" style={{ display: 'inline', fontWeight: 'normal' }} />
              </a>
            </div>
            <div className="w-full lg:w-1/2 px-4">
              <img className="w-full" src={moonCircle} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="relative py-20 2xl:py-40 bg-gray-800 overflow-hidden">
        <div className="relative container px-4 mx-auto">
          <div>
            <div className="flex flex-wrap -mx-10">
              <HomeShoutout
                title="Everything on Chain"
                body="There are no game servers, so all the data, rules, and gameplay is on the blockchain"
                image={nft0}
                moons={false}
              />
              <HomeShoutout
                title="RPG gameplay"
                body="Your sky castle is your hero, and you can improve their stats and skills as you go on adventures with them"
                image={nft1}
                moons={true}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-blue-600 py-20 2xl:py-40 overflow-hidden">
        <div className="container px-4 mx-auto">
          <div className="max-w-4xl mx-auto text-center">
            <span className="text-white font-bold">We're live on testnets</span>
            <h2 className="my-8 mb-20 text-6xl lg:text-7xl text-white font-bold font-heading">Mint your castle and start exploring</h2>
            <Link
              className="inline-block py-4 px-12 text-white font-bold bg-blue-500 hover:bg-blue-400 rounded-full transition duration-200"
              to="/mint"
            >
              Learn how
            </Link>
          </div>
        </div>
      </section>
      <section className="bg-gray-800 py-20 2xl:py-40 overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="max-w-xl mx-auto mb-12 lg:mb-28 text-center">
            <span className="text-lg text-blue-400 font-semibold">Procedural, fully 3-d</span>
            <h2 className="mt-8 text-5xl text-white font-bold font-heading">Every Castle is a uniquely generated 3-d model</h2>
          </div>
          <div className="flex flex-wrap -mx-5">
            <div className="w-full lg:w-1/3 px-5 pt-10 lg:pt-20 pb-10">
              <ul>
                <li className="pb-5 border-b border-gray-500">
                  <div className="inline-block py-6 px-12 text-lg text-gray-300" href="#">
                    over 2 billion possible castles
                  </div>
                </li>
                <li className="py-6 border-b border-gray-500">
                  <div className="inline-block py-6 px-12 text-lg text-gray-300" href="#">
                    unique flying methods
                  </div>
                </li>
                <li className="py-6 border-b border-gray-500">
                  <div className="inline-block py-6 px-12 text-lg text-gray-300" href="#">
                    one unique tree per castle
                  </div>
                </li>
                <li>
                  <div className="inline-block py-6 px-12 text-lg text-gray-300" href="#">
                    unique colors and skies
                  </div>
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-2/3 px-5">
              <div className="flex flex-wrap -px-4">
                <div className="w-full md:w-1/3 p-4">
                  <img className="h-96 w-full object-cover" src={nft2} alt="" />
                </div>
                <div className="w-full md:w-2/3 p-4">
                  <img className="h-96 object-cover w-full" src={nft3} alt="" />
                </div>
                <div className="w-full md:w-2/3 p-4">
                  <img className="h-96 object-cover w-full" src={nft4} alt="" />
                </div>
                <div className="w-full md:w-1/3 p-4">
                  <img className="h-96 w-full object-cover" src={nft5} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
