import React from 'react';
import { Link } from 'react-router-dom';
import card0 from '../img/nft0.png';

function CastleCard(props) {
  let stats = [
    { name: 'Authority', value: props.castle.stats[0] },
    { name: 'Sustainability', value: props.castle.stats[1] },
    { name: 'Resiliency', value: props.castle.stats[2] },
    { name: 'Literacy', value: props.castle.stats[3] },
    { name: 'Creativity', value: props.castle.stats[4] },
    { name: 'Culture', value: props.castle.stats[5] },
  ];

  let skills = [
    { name: 'Astromancy', value: props.castle.skills[0] },
    { name: 'Horomancy', value: props.castle.skills[1] },
    { name: 'Geomancy', value: props.castle.skills[2] },
    { name: 'Technomancy', value: props.castle.skills[3] },
    { name: 'Lunacy', value: props.castle.skills[4] },
  ];

  return (
    <Link to={'/castle/' + props.castle.id}>
      <div className="card-normal w-96 rounded-lg bg-primary-content shadow-xl m-auto">
        <figure>
          <img src={card0} alt="Castle" className="rounded-lg w-96 m-1" />
        </figure>
        <div className="card-body">
          <h2 className="card-title">Castle {props.castle.id.toString()}</h2>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <ul className="list-disc text-left	">
                {stats.map((object, i) => (
                  <li key={i}>
                    {object.name} <kbd className="kbd kbd-sm">{object.value}</kbd>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <ul className="list-disc text-left	">
                {skills.map((object, i) => (
                  <li key={i}>
                    {object.name} <kbd className="kbd kbd-sm">{object.value}</kbd>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default CastleCard;
