import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNetwork } from 'wagmi';
import NotYet from '../components/NotYet';
import ChooseGame from '../components/play/ChooseGame';
import Explore from '../components/play/explore/Explore';
import HeldCastle from '../components/wallet/HeldCastle';
import { isDev } from '../utils/Networks';

function Play(props) {
  console.log('loading play');
  let { id } = useParams();

  if (id === undefined) {
    id = props.castles[0]?.id?.toNumber();
    console.log('id', id);
  }

  // console.log('play: ' + JSON.stringify(props));
  const [{ data: networkData, error: networkError, loading: networkLoading }, switchNetwork] = useNetwork();
  const [game, setGame] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    setTime();
  }, []);

  async function setTime() {
    const curBlock = await props.signer.provider.getBlockNumber();
    const curTime = (await props.signer.provider.getBlock(curBlock)).timestamp;
    setCurrentTime(curTime);
  }

  const games = [
    {
      name: 'Explore',
      description: 'Explore the world',
      bullet1: 'Explore the surface',
      bullet2: 'Choose wisely',
      bullet3: 'Collect supplies',
    },
    {
      name: 'Party',
      description: 'Party with your friends',
      bullet1: 'Use your supplies',
      bullet2: 'Invite your friends',
      bullet3: 'Celebrate the skies',
    },
    {
      name: 'Crash',
      description: 'Crash another players party',
      bullet1: 'Use your supplies',
      bullet2: 'Choose Wisely',
      bullet3: 'Collect Resources',
    },
  ];

  if (isDev(networkData.chain?.id)) {
    return (
      <div>
        <div className="flex flex-wrap -mx-4 -mb-4 md:mb-0">
          <div className="w-full md:w-1/3 px-4 mb-4 md:mb-0">
            <section className="relative py-20 overflow-hidden">
              <img className="absolute top-0 left-0 -mt-80 -ml-96" src="zospace-assets/lines/circle.svg" alt="" />
              <img className="absolute bottom-0 right-0 h-full -mb-40 -mr-96" src="zospace-assets/lines/circle.svg" alt="" />
              <div className="relative container px-4 mx-auto">
                <h2 className="mb-24 text-center text-5xl font-bold font-heading text-white">Castles</h2>
                <div className="flex flex-wrap justify-center -mx-5 mb-20">
                  <div className="w-full px-5 mb-10 lg:mb-0">
                    <HeldCastle castle={props.castles[id]} />
                  </div>
                </div>
                <div className="text-center">
                  <button className="inline-flex mr-2 items-center justify-center w-14 h-14 bg-blue-500 hover:bg-blue-600 rounded-full">
                    <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.84708 12.115C7.05097 12.3207 7.05097 12.6512 6.84708 12.8554C6.64319 13.0596 6.31377 13.0603 6.10987 12.8554L0.152917 6.87493C-0.0509739 6.67074 -0.0509738 6.34026 0.152917 6.13456L6.10988 0.154119C6.31377 -0.0500693 6.64319 -0.0500693 6.84708 0.154119C7.05097 0.359063 7.05097 0.690301 6.84708 0.894489L1.4143 6.50512L6.84708 12.115Z"
                        fill="white"
                      ></path>
                    </svg>
                  </button>
                  <button className="inline-flex items-center justify-center w-14 h-14 bg-blue-500 hover:bg-blue-600 rounded-full">
                    <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.15292 0.901511C-0.0509709 0.696057 -0.0509709 0.365972 0.15292 0.16203C0.356811 -0.0419136 0.686232 -0.0426689 0.890123 0.16203L6.84708 6.13528C7.05097 6.33923 7.05097 6.66931 6.84708 6.87476L0.890121 12.848C0.686231 13.052 0.35681 13.052 0.152919 12.848C-0.050972 12.6433 -0.0509719 12.3125 0.152919 12.1085L5.5857 6.50465L0.15292 0.901511Z"
                        fill="white"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </section>
          </div>
          <div className="w-full md:w-2/3 px-4 mb-4 md:mb-0">
            {game !== 0 ? <ChooseGame games={games} setGame={setGame} /> : <> </>}

            <Explore
              id={id}
              currentTime={currentTime}
              castle={props.castles[id]}
              explorerAddress={props.explorerAddress}
              signer={props.signer}
              setReload={props.setReload}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <NotYet
        title="Profiles are coming to the testnet first"
        text="When minting is live you'll be able to view your castles stats here.  In the meantime, you can try out minting on the Polygon Mumbai testnet!"
      ></NotYet>
    );
  }
}

export default Play;
