import React, { Component } from 'react';
import NotYet from './NotYet';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: '' };
  }

  componentDidCatch(error) {
    this.setState({ error: `${error.name}: ${error.message}` });
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <NotYet
          title="Something went wrong, please go back to the home page and reload!"
          text="We're still building our site - sorry for the inconvenience!  Please go back home and come back to this page and it should work."
          subtext={error}
        ></NotYet>
      );
    } else {
      return <>{this.props.children}</>;
    }
  }
}
export default ErrorBoundary;
