import React from 'react';
import { RiArrowRightUpLine, RiTwitterFill, RiDiscordFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

function Footer(props) {
  const links = [
    { name: 'Wallet', path: '/wallet' },
    { name: 'Mint', path: '/mint' },
    { name: 'Play', path: '/play' },
  ];

  return (
    <section className="py-20 2xl:py-20 bg-gray-800">
      <div className="container mx-auto px-4 mb-12 md:mb-20">
        <div className="max-w-4xl mx-auto text-center">
          <ul className="mb-12 md:mb-20 flex flex-wrap items-center justify-center space-x-6 md:justify-between text-lg">
            {links.map((link, i) => (
              <React.Fragment key={i}>
                <li>
                  <Link className="text-white font-bold text-lg" to={link.path}>
                    {link.name}
                  </Link>
                </li>
                <span>
                  <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2.5" cy="2.5" r="2.5" fill="#726B6B"></circle>
                  </svg>
                </span>
              </React.Fragment>
            ))}
            <li>
              <a className="text-white font-bold text-lg" target="_blank" rel="noopener noreferrer" href="https://docs.twomoons.xyz">
                Whitepaper
                <RiArrowRightUpLine className="p-0.5" style={{ display: 'inline', fontWeight: 'normal' }} />
              </a>
            </li>
          </ul>
          <div className="flex justify-center">
            <a className="flex justify-center items-center w-12 h-12 mr-4 bg-gray-600 rounded-full" href="https://twitter.com/twomoonsxyz">
              <RiTwitterFill size="30" />
            </a>
            <a className="flex justify-center items-center w-12 h-12 mr-4 bg-gray-600 rounded-full" href="https://discord.gg/GPem9DXKcy">
              <RiDiscordFill size="30" />
            </a>
          </div>
        </div>
      </div>
      <p className="text-center text-lg text-white pt-12 px-4 border-t border-gray-500">
        <span> Two Moons is passion project. Thanks for checking it out.</span>
      </p>
      <p className="text-center text-lg text-white pt-4 px-4 ">
        <span>© 2021 Two Moons.</span> <span className="text-gray-300">All rights reserved.</span>
      </p>
    </section>
  );
}

export default Footer;
