import dayjs from 'dayjs';
import React from 'react';

function LiveRegion(props) {
  return (
    <section className="bg-gray-800">
      <div className="container mx-auto px-4">
        <div className="mb-10 text-center">
          <h2 className="mb-8  text-5xl text-white font-bold font-heading">Landed in a {props.activeRegion}</h2>
        </div>
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-wrap items-center -mx-3">
            <p className="p-10 text-xl text-gray-200">
              Your castle has found it's way to the surface, and is now located in a{' '}
              <span className="font-bold text-2xl"> {props.activeRegion}</span> region. With a clear view of your surroundings you are able
              to see structures and buildings in the area.
            </p>
            <p className="p-10 text-xl mx-auto">
              Your castle will be in the {props.activeRegion} until:{' '}
              <span>
                {dayjs()
                  .second(props.castle.regionLockExpire - props.currentTime)
                  .format('MMMM D, h:mm A, YYYY')}
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LiveRegion;
