import React from 'react';
import nft0 from '../../img/nft0.png';

function HeldCastle(props) {
  let stats = [
    { name: 'Authority', value: props.castle.stats[0] },
    { name: 'Sustainability', value: props.castle.stats[1] },
    { name: 'Resiliency', value: props.castle.stats[2] },
    { name: 'Literacy', value: props.castle.stats[3] },
    { name: 'Creativity', value: props.castle.stats[4] },
    { name: 'Culture', value: props.castle.stats[5] },
  ];

  let skills = [
    { name: 'Astromancy', value: props.castle.skills[0] },
    { name: 'Horomancy', value: props.castle.skills[1] },
    { name: 'Geomancy', value: props.castle.skills[2] },
    { name: 'Technomancy', value: props.castle.skills[3] },
    { name: 'Lunacy', value: props.castle.skills[4] },
  ];

  // let stats = [
  //   { name: 'Authority', value: 1 },
  //   { name: 'Sustainability', value: 1 },
  //   { name: 'Resiliency', value: 1 },
  //   { name: 'Literacy', value: 1 },
  //   { name: 'Creativity', value: 1 },
  //   { name: 'Culture', value: 1 },
  // ];

  // let skills = [
  //   { name: 'Astromancy', value: 1 },
  //   { name: 'Horomancy', value: 1 },
  //   { name: 'Geomancy', value: 1 },
  //   { name: 'Technomancy', value: 1 },
  //   { name: 'Lunacy', value: 1 },
  // ];

  return (
    // <div class="w-full lg:w-1/3 px-5 mb-10 lg:mb-0 py-5">
    <>
      <img className="w-full h-112 lg:h-80 object-cover object-top" src={nft0} alt="" />
      <div className="py-10 px-16 bg-gray-700 rounded-b-lg text-center">
        <h3 className="mb-2 text-3xl text-white font-bold font-heading">Castle #{props.castle.id.toString()}</h3>
        <p className="mb-10 text-white">Godly</p>
        <div className="py-px mb-8 bg-gray-500"></div>
        <div className="grid grid-cols-2 gap-2 -mx-6">
          <div>
            <ul className="list-disc text-left	">
              {stats.map((object, i) => (
                <li key={i}>
                  {object.name} <kbd className="kbd kbd-sm">{object.value}</kbd>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <ul className="list-disc text-left	">
              {skills.map((object, i) => (
                <li key={i}>
                  {object.name} <kbd className="kbd kbd-sm">{object.value}</kbd>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
    // </div>
  );
}

export default HeldCastle;
