import React from 'react';
import ChooseRegionCard from './ChooseRegionCard';

function ChooseRegion(props) {
  return (
    <section className="bg-gray-800">
      <div className="container mx-auto px-4">
        <div className="mb-10 text-center">
          <h2 className="mb-8  text-5xl text-white font-bold font-heading">Choose a Region</h2>
        </div>
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-wrap items-center -mx-3">
            <p className="p-10 text-xl text-gray-200">
              The ground below your castle is vast and diverse, yet covered in a hazy fog. From your vantage point in the skies above, you
              can only see landscape features of the regions on the surface.
            </p>
            <p className="p-10 text-xl mx-auto">
              To begin your exploration, you must first decide upon a region to descend with your castle.
            </p>
          </div>
          <div className="flex flex-wrap items-center">
            {props.regionNames.map((name, i) => (
              <ChooseRegionCard
                key={i}
                index={i}
                name={name}
                id={props.id}
                explorerContract={props.explorerContract}
                setReload={props.setReload}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChooseRegion;
