import dayjs from 'dayjs';
import React from 'react';

function LiveBuilding(props) {
  async function resolveAction() {
    const tx = await props.explorerContract.resolveAction(props.castle.id);
    const receipt = await tx.wait();
    console.log('receipt', receipt);
  }
  return (
    <section className="pt-20  bg-gray-800">
      <div>
        <p className="p-10 text-xl text-gray-200">
          Your castle is busy as it
          <span className="font-bold text-2xl"> {props.liveAction[2]}s</span>, which it will finish at:
        </p>

        <p className="font-bold text-2xl">
          {dayjs()
            .second(props.castle.actionLockExpire - props.currentTime)
            .format('MMMM D, h:mm A, YYYY')}
        </p>
      </div>
      <div className="text-center pt-20">
        <button
          className="inline-block px-10 py-4 border border-gray-200 hover:border-gray-100 rounded-full font-bold text-white"
          onClick={() => {
            resolveAction();
          }}
        >
          Explore the {props.name}
        </button>
      </div>
    </section>
  );
}

export default LiveBuilding;
