import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Mint from './pages/Mint';
import Wallet from './pages/Wallet';
import Castle from './pages/Castle';
import { useEffect, useRef, useState } from 'react';
import State from './utils/State';
import { providers } from 'ethers';
import { chain, useNetwork } from 'wagmi';
import { get } from './utils/Networks';
import ErrorBoundary from './components/ErrorBoundry';
import NotYet from './components/NotYet';
import Play from './pages/Play';
import Toast from './components/alerts/Toast';

function App(props) {
  console.log('loading app');

  const [{ data: networkData, error: networkError, loading: networkLoading }] = useNetwork();

  const [castles, setCastles] = useState([]);
  const [signer, setSigner] = useState();
  const [totalSupply, setTotalSupply] = useState(0);
  const [cost, setCost] = useState(0);
  const [balance, setBalance] = useState(0);
  const [network, setNetwork] = useState();
  const [tokens, setTokens] = useState([]);

  // ------------------------------ addresses
  const [castleAddress, setCastleAddress] = useState();
  const [statsAddress, setStatsAddress] = useState();
  const [regionAddress, setRegionAddress] = useState();
  const [explorerAddress, setExplorerAddress] = useState();
  const [dragonToothAddress, setDragonToothAddress] = useState();
  const [glassShardAddress, setGlassShardAddress] = useState();

  // ------------------------------ loading
  const [reload, setReload] = useState();
  const [loaded, setLoaded] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [autoDeleteTime, setAutoDeleteTime] = useState(0);

  const triggerReload = () => {
    console.log('triggering reload');
    setReload(reload + 1);
  };

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    loadNetwork();
  }, [networkData?.chain?.id]);

  useEffect(() => {
    showToast(
      'Early Access',
      'Two Moons is in VERY early access!  Reload the page in your browser after submitting transaction',
      'info',
      false
    );
  }, []);

  async function loadNetwork() {
    if (networkData !== network) {
      setNetwork(networkData);
      setCastleAddress(get(networkData?.chain?.id)?.addresses.skyCastle);
      setStatsAddress(get(networkData?.chain?.id)?.addresses.skyCastleStats);
      setExplorerAddress(get(networkData?.chain?.id)?.addresses.explorer);
      setRegionAddress(get(networkData?.chain?.id)?.addresses.region);
      setDragonToothAddress(get(networkData?.chain?.id)?.addresses.dragonTooth);
      setGlassShardAddress(get(networkData?.chain?.id)?.addresses.glassShard);
    }
  }

  function showToast(title, description, level, auto = true) {
    const toastProperties = {
      id: Math.floor(Math.random() * 101 + 1),
      title: title,
      description: description,
      level: level,
      autoDelete: auto,
    };

    setToastList([...toastList, toastProperties]);
  }

  return (
    <div className="App font-sahitya">
      {network?.chain ? (
        <State
          loaded={loaded}
          setLoaded={setLoaded}
          reload={reload}
          setReload={triggerReload}
          network={network}
          castleAddress={castleAddress}
          statsAddress={statsAddress}
          regionAddress={regionAddress}
          glassShardAddress={glassShardAddress}
          dragonToothAddress={dragonToothAddress}
          explorerAddress={explorerAddress}
          setCastles={setCastles}
          setSigner={setSigner}
          setTotalSupply={setTotalSupply}
          setCost={setCost}
          setBalance={setBalance}
          setTokens={setTokens}
        />
      ) : (
        <></>
      )}
      {true ? (
        <BrowserRouter>
          <Navbar />
          <Toast toastList={toastList} autoDelete={true} />
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="wallet" element={<Wallet tokens={tokens} balance={balance} castles={castles} />} />

              <Route
                path="play"
                element={
                  loaded === true ? (
                    <>
                      {castles.length > 0 ? (
                        <Play
                          network={network}
                          castleAddress={castleAddress}
                          statsAddress={statsAddress}
                          explorerAddress={explorerAddress}
                          castles={castles}
                          signer={signer}
                          setReload={triggerReload}
                        />
                      ) : (
                        <NotYet title="Mint a castle first!" text="You need a castle to play games!"></NotYet>
                      )}
                    </>
                  ) : (
                    <NotYet
                      title="Loading from the blockchain"
                      text="Check your wallet is connected at the top right of the screen and give the internet a minute!"
                    ></NotYet>
                  )
                }
              />

              <Route
                path="play/:id"
                element={
                  loaded === true ? (
                    <Play
                      network={network}
                      castleAddress={castleAddress}
                      statsAddress={statsAddress}
                      explorerAddress={explorerAddress}
                      castles={castles}
                      signer={signer}
                      setReload={triggerReload}
                    />
                  ) : (
                    <NotYet
                      title="Loading from the blockchain"
                      text="Check your wallet is connected at the top right of the screen and give the internet a minute!"
                    ></NotYet>
                  )
                }
              />

              <Route
                path="mint"
                element={
                  loaded === true ? (
                    <Mint
                      setReload={triggerReload}
                      network={network}
                      castleAddress={castleAddress}
                      statsAddress={statsAddress}
                      signer={signer}
                      totalSupply={totalSupply}
                      cost={cost}
                      showToast={showToast}
                    />
                  ) : (
                    <NotYet
                      title="Loading from the blockchain"
                      text="Check your wallet is connected at the top right of the screen and give the internet a minute!"
                    ></NotYet>
                  )
                }
              />
              <Route
                path="castle/:id"
                element={
                  loaded === true ? (
                    <Castle
                      network={network}
                      castleAddress={castleAddress}
                      statsAddress={statsAddress}
                      explorerAddress={explorerAddress}
                      castles={castles}
                      signer={signer}
                      setReload={triggerReload}
                    />
                  ) : (
                    <NotYet
                      title="Loading from the blockchain"
                      text="Check your wallet is connected at the top right of the screen and give the internet a minute!"
                    ></NotYet>
                  )
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ErrorBoundary>
          <Footer />
        </BrowserRouter>
      ) : (
        <NotYet
          title="Loading from the blockchain"
          text="Check your wallet is connected at the top right of the screen and give the internet a minute!"
        ></NotYet>
      )}
    </div>
  );
}

export default App;
