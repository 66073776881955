import React from 'react';
import moon2 from '../../img/moon2.svg';

function ChooseGameCard(props) {
  return (
    <div class="w-full lg:w-1/3 px-3 mb-8 lg:mb-0">
      <div class="px-12 py-16 bg-gray-500 hover:bg-blue-500 rounded-3xl">
        <div class="pb-8 mb-14 border-b border-gray-400">
          <h3 class="text-4xl text-white font-bold font-heading">{props.game.name}</h3>
        </div>
        <ul class="text-lg text-white mb-16">
          <li class="flex items-center mb-8">
            <span class="mr-6">
              <img src={moon2} alt="" />
            </span>
            <span>{props.game.bullet1}</span>
          </li>
          <li class="flex items-center mb-8">
            <span class="mr-6">
              <img src={moon2} alt="" />
            </span>
            <span>{props.game.bullet2}</span>
          </li>
          <li class="flex items-center">
            <span class="mr-6">
              <img src={moon2} alt="" />
            </span>
            <span>{props.game.bullet3}</span>
          </li>
        </ul>
        <div class="text-center">
          <a class="inline-block px-10 py-4 border border-gray-200 hover:border-gray-100 rounded-full font-bold text-white" href="#">
            Try free
          </a>
        </div>
      </div>
    </div>
  );
}

export default ChooseGameCard;
