import dayjs from 'dayjs';
import React, { useState } from 'react';

function BuildingSelector(props) {
  const [activeBuildingIndex, setActiveBuildingIndex] = useState(-1);
  const [actionNames, setActionNames] = useState([]);
  const [activeActionIndex, setActiveActionIndex] = useState(-1);

  function renderBuildings() {
    return props.buildingNames.map((region, index) => {
      return (
        <button
          key={index}
          className={'tab tab-lg ' + getActive(index)}
          onClick={() => {
            handleBuildingClick(index);
          }}
        >
          {region}
        </button>
      );
    });
  }

  function renderActions() {
    return actionNames.map((region, index) => {
      return (
        <button
          key={index}
          className={'tab tab-lg ' + getActiveAction(index)}
          onClick={() => {
            handleActionClick(index);
          }}
        >
          {region}
        </button>
      );
    });
  }

  async function handleBuildingClick(index) {
    setActiveBuildingIndex(index);

    const actions = await props.explorerContract.getActions(props.castle.id, props.buildingNames[index]);
    setActiveActionIndex(-1);
    setActionNames(actions);
  }

  function handleActionClick(index) {
    if (index !== activeActionIndex) {
      setActiveActionIndex(index);
    }
  }

  function getActiveAction(index) {
    return index === activeActionIndex ? 'tab-active' : '';
  }

  function getActive(index) {
    return index === activeBuildingIndex ? 'tab-active' : '';
  }

  async function startAction() {
    console.log('performAction: ', props.castle.id, activeBuildingIndex, activeActionIndex);
    try {
      const buildings = await props.explorerContract.getMyBuildings(props.castle.id);
      console.log('buildings: ', buildings);
      const actions = await props.explorerContract.getActions(props.castle.id, buildings[activeBuildingIndex]);
      console.log('actions: ', actions);
      const tx = await props.explorerContract.performAction(props.castle.id, activeBuildingIndex, activeActionIndex);
      const receipt = await tx.wait();
      props.setReload(true);
    } catch (e) {
      console.error(e, e.stack);
    }
  }

  return (
    <>
      {props.activeRegion !== null ? (
        <div>
          {props.liveAction !== null ? (
            <div>
              <p className="p-10">
                Your castle is busy as it
                <span className="font-bold text-2xl"> {props.liveAction[2]}s</span> which it will finish at
                {/* the{' '}
                <span className="font-bold text-2xl"> {props.buildingNames[props.liveAction[]]}s</span> */}
              </p>
              <p className="font-bold text-2xl">
                {dayjs()
                  .second(props.castle.actionLockExpire - props.currentTime)
                  .format('MMMM D, h:mm A, YYYY')}
              </p>
            </div>
          ) : (
            <div>
              <p className="p-10">To contine your exploration you must next decide upon a building to visit and an action to perform.</p>
              <h2 className="card-title pt-12">Choose a Building</h2>
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <div className="grid grid-cols-3 gap-4 tabs tabs-boxed">{renderBuildings()}</div>
                </div>
              </div>
              <div>
                {activeBuildingIndex !== -1 ? (
                  <div>
                    <h2 className="card-title">Choose an Action</h2>
                    <div className="grid grid-cols-3 gap-4 tabs tabs-boxed">{renderActions()}</div>
                  </div>
                ) : (
                  <></>
                )}
                {activeActionIndex !== -1 && activeActionIndex !== -1 ? (
                  <button
                    className="btn btn-primary m-10"
                    onClick={() => {
                      startAction();
                    }}
                  >
                    {actionNames[activeActionIndex]} at the {props.buildingNames[activeBuildingIndex]}
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
          ;
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default BuildingSelector;
