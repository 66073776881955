import { success } from 'daisyui/src/colors';
import { useState, useEffect } from 'react';
import { RiCloseFill, RiEmotionHappyFill, RiErrorWarningFill, RiInformationFill } from 'react-icons/ri';

const Toast = props => {
  const { toastList, position, autoDelete } = props;
  const [list, setList] = useState(toastList);

  const autoDeleteTime = 5000;

  useEffect(() => {
    setList([...toastList]);
  }, [toastList]);

  const mode = [
    {
      name: 'success',
      icon: <RiEmotionHappyFill size="50" />,
      class: 'alert alert-success shadow-lg',
    },
    {
      name: 'info',
      icon: <RiInformationFill siz="50" />,
      class: 'alert-warning',
    },
    {
      name: 'error',
      icon: <RiErrorWarningFill size="50" />,
      class: 'alert alert-error shadow-lg',
    },
  ];

  function getMode(key) {
    let m = mode.find(item => item.name === key);
    return m;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        if (!toastList[0].autoDelete) {
          return;
        }
        deleteToast(toastList[0].id);
      }
    }, autoDeleteTime);

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line
  }, [toastList, autoDelete, autoDeleteTime, list]);

  const deleteToast = id => {
    const listItemIndex = list.findIndex(e => e.id === id);
    const toastListItem = toastList.findIndex(e => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    setList([...list]);
  };
  return (
    <>
      <div className={`box-border fixed z-50 transition-transform top-50 right-10`}>
        {list.map((toast, i) => (
          <div key={i} className={`alert ${getMode(toast.level).class} shadow-lg`}>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
                {getMode(toast.level).icon}
              </svg>
              <div>
                <p className="pl-5 text-xl">{toast.title}</p>
                <p>{toast.description}</p>
              </div>
            </div>
            <button onClick={() => deleteToast(toast.id)}>
              <RiCloseFill size="20" />
            </button>
          </div>
          // <div key={i} className={`notification h-12 w-96 p-15 ${position}`} style={{ backgroundColor: toast.backgroundColor }}>
          //   <button onClick={() => deleteToast(toast.id)}>X</button>
          //   <div className="notification-image">
          //     <img src={toast.icon} alt="" />
          //   </div>
          //   <div>
          //     <p className="notification-title">{toast.title}</p>
          //     <p className="notification-message">{toast.description}</p>
          //   </div>
          // </div>
        ))}
      </div>
    </>
  );
};

export default Toast;

// .notification-container {
// 	font-size: 14px;
// 	box-sizing: border-box;
// 	position: fixed;
// 	z-index: 999999
// }

// .top-right {
// 	top: 12px;
// 	right: 12px;
// 	transition: transform .6s ease-in-out;
// 	animation: toast-in-right .7s;
// }

// .toast {
//   height: 50px;
//   width: 365px;
// color: #fff;
// padding: 20px 15px 10px 10px;
// }
