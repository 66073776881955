import React from 'react';

function ChooseRegionCard(props) {
  const regions = [
    {
      name: 'strange',
      description: 'This is a strange region',
    },
    {
      name: 'swamp',
      description: 'This is a swamp region',
    },
    {
      name: 'mountains',
      description: 'Tumultuous peaks and flash storms are common in the mountains.  ',
    },
    {
      name: 'forest',
      description: 'This is a forest region',
    },
    {
      name: 'desert',
      description: 'This is a desert region',
    },

    {
      name: 'mirror',
      description: 'This is a mirror region',
    },
  ];

  async function selectRegion(selected) {
    console.log('selectRegion', selected);
    console.log('id', props.id);
    const tx = await props.explorerContract.chooseRegion(props.id, selected);

    const receipt = await tx.wait();
    props.setReload(true);
  }

  return (
    <div className="w-full lg:w-1/3 px-3 mb-8 lg:mb-0 min-h-[10]">
      <div className="px-4 pt-16 pb-10 bg-gray-500 hover:bg-blue-500 rounded-3xl ">
        <div className="pb-8 mb-14 border-b border-gray-400">
          <h3 className="text-4xl text-white font-bold font-heading">{props.name}</h3>
        </div>
        <div>
          <p>{regions.find(r => r.name === props.name).description}</p>
        </div>
        <div className="text-center pt-10">
          <button
            className="inline-block px-10 py-4 border border-gray-200 hover:border-gray-100 rounded-full font-bold text-white"
            onClick={() => {
              selectRegion(props.index);
            }}
          >
            Explore the {props.name}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChooseRegionCard;
