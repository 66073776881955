import React from 'react';
import addresses from '../chain/testnet-phase-one.json';
import SkyCastleABI from '../chain/SkyCastle.json';
import nft0 from '../img/nft0.png';
import { chain } from 'wagmi';

import { useContract } from 'wagmi';
import { ethers } from 'ethers';
import { useNetwork } from 'wagmi';
import NotYet from '../components/NotYet';
import { get, isDev } from '../utils/Networks';
import blob1 from '../img/blob1.svg';
import moon1 from '../img/moon1.svg';
import moon2 from '../img/moon2.svg';

function Mint(props) {
  console.log('loading mint: ');

  // const [{ data: signer, error, loading }, getSigner] = useSigner();
  const [{ data: networkData, error: networkError, loading: networkLoading }, switchNetwork] = useNetwork();

  let castleContract = null;
  castleContract = useContract({
    // addressOrName: addresses.skyCastle,
    // addressOrName: get(networkData.chain?.id).addresses.skyCastle,
    addressOrName: props.castleAddress,
    contractInterface: SkyCastleABI.abi,
    signerOrProvider: props.signer,
  });

  async function mintOne() {
    try {
      props.showToast('Minting', 'Approve the transaction and give it a minute!', 'info');
      const tx = await castleContract.mint({ value: props.cost });
      await tx.wait();
      props.showToast('Minted!', 'You might have to reload your browser if it doesn`t automatically', 'success');
      props.setReload(true);
    } catch (e) {
      props.showToast('Try Again!', 'something went wrong', 'error');
      console.log(JSON.stringify(e));
    }
  }

  if (isDev(networkData.chain?.id)) {
    return (
      <>
        <section className="relative py-20 2xl:py-40 bg-gray-800">
          <img className="block absolute top-0 left-0 w-2/5" src={blob1} alt="" />
          <img className="block absolute top-0 left-0 mt-20 ml-10" src={moon1} alt="" />
          <img className="block absolute top-0 left-0 mt-40 ml-40" src={moon2} alt="" />
          <div className="relative container px-4 mx-auto">
            <div className="text-center mb-20">
              <h2 className="mt-10 text-5xl font-bold font-heading text-white">Mint your castle</h2>
            </div>
            <div className="max-w-4xl mx-auto">
              <div className="flex flex-wrap -mx-4 mb-7">
                <div className="w-full lg:w-1/2 px-4 mb-4 lg:mb-0 ">
                  <div className="bg-blue-500 rounded-b-lg p-5 ">
                    <img alt="minting" src={nft0} className="mx-auto rounded-lg shadow-2xl" />
                    {/* 
                    <img
                      class="h-64 w-full rounded-lg object-cover"
                      src="https://image.freepik.com/free-photo/contacting-with-friends-via-computer_329181-14587.jpg"
                      alt=""
                    /> */}
                    {/* <div class="px-14 py-8">
                      <div class="mb-6 py-px bg-gray-500"></div>
                      <h3 class="mb-8 lg:mb-4 text-xl text-white font-bold">Have any question? Send a message!</h3>
                      <div class="md:text-right">
                        <a
                          class="inline-block px-12 py-4 border border-gray-300 hover:border-gray-200 rounded-full font-bold text-white"
                          href="#"
                        >
                          Message
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="w-full lg:w-1/2 px-4">
                  <div className="py-6 px-8 mb-4 bg-gray-600 rounded-lg">
                    <div className="flex items-center">
                      <div className=" mx-auto">
                        <div className="text-gray-300">Current Era</div>
                        <div className="text-white font-bold text-3xl">ASGARD</div>
                        <div className="text-gray-300">88 points</div>
                      </div>
                    </div>
                  </div>
                  <div className="py-6 px-8 mb-4 bg-gray-600 rounded-lg">
                    <div className="flex items-center">
                      <div className=" mx-auto">
                        <div className="text-gray-300">Current Cost</div>
                        <div className="text-white font-bold text-3xl">{ethers.utils.formatEther(props.cost.toString())} </div>
                        <div className="text-gray-300">MATIC</div>
                      </div>
                    </div>
                  </div>
                  <div className="py-6 px-8 mb-4 bg-gray-600 rounded-lg">
                    <div className="flex items-center">
                      <div className=" mx-auto">
                        <div className="text-gray-300">Current open supply</div>
                        <div className="text-white font-bold text-3xl">{props.totalSupply.toString()}</div>
                        <div className="text-gray-300"> / 500</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  className="gap-2 px-10 py-4 border hover:border-white rounded-full text-2xl m-10"
                  onClick={() => {
                    mintOne();
                  }}
                >
                  Mint a Castle
                </button>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <NotYet
        title="Minting is coming to the testnet first"
        text="When minting is live you'll be able to do it here.  In the meantime, you can connect your metamask to the Polygon Mumbai testnet to mint!"
      ></NotYet>
    );
  }
}

export default Mint;
