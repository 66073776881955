import { BigNumber, ethers } from 'ethers';
import React from 'react';

function Token(props) {
  return (
    <div className="w-full md:w-1/2 lg:w-1/4 p-6">
      <div className="py-10 px-3 bg-gray-600 rounded-lg">
        <h3 className=" mb-3 text-xl font-bold font-heading text-white">{props.name}</h3>
        <h3 className="mb-6 text-3xl font-bold font-heading text-white">
          {ethers.utils.formatEther(BigNumber.from(props.held.toString()))}
        </h3>
        <p className="text text-gray-200">{props.description}</p>
      </div>
    </div>
  );
}

export default Token;
