import React, { useEffect, useState } from 'react';

function ChooseBuildingCard(props) {
  const [actions, setActions] = useState([]);

  useEffect(() => {
    console.log('loading choose building data');
    loadData();
  }, [props.actionSet]);

  async function loadData() {
    setActions(props.actionSet[props.index]);

    console.log(actions);
  }

  async function selectAction(actionIndex) {
    console.log('selectedBuildingIndex: ', props.index);
    console.log('selectedActionIndex', actionIndex);
    const tx = await props.explorerContract.performAction(props.id, props.index, actionIndex);
    const receipt = await tx.wait();
    props.setReload(true);
  }

  return (
    <div className="p-6 lg:p-12 mb-8 bg-gray-600  hover:bg-blue-500 rounded-3xl">
      <div className="flex flex-wrap items-center -mx-4">
        <div className="w-full lg:w-2/6 px-4 mb-10 lg:mb-0">
          <h3 className="text-3xl font-bold font-heading text-white">{props.name}</h3>
          <p>blah blah balh</p>
        </div>
        <div className="w-full  lg:w-4/6 px-4 mb-10 lg:mb-0 text-center lg:border-l border-gray-500">
          <ul className="columns-3">
            {actions?.map((name, i) => (
              <li key={i} className="mb-4">
                <button
                  className="inline-block px-10 py-4 border border-gray-200 hover:border-gray-100 rounded-full font-bold text-white"
                  onClick={() => {
                    selectAction(i);
                  }}
                >
                  {name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ChooseBuildingCard;
