import React from 'react';
import ChooseBuildingCard from './ChooseBuildingCard';

function ChooseBuilding(props) {
  return (
    <section className="py-20  bg-gray-800">
      <div className="container px-4 mx-auto">
        <div className="mb-16 md:mb-24 text-center">
          <h2 className="mb-8  text-5xl text-white font-bold font-heading">Choose a Building</h2>
          <p className="text-lg text-gray-200">The quick brown fox jumps over the lazy dog.</p>
        </div>
        <div className="max-w-7xl mx-auto">
          {props.buildingNames.map((name, i) => (
            <ChooseBuildingCard
              key={i}
              index={i}
              name={name}
              id={props.id}
              explorerContract={props.explorerContract}
              actionSet={props.actionSet}
              setReload={props.setReload}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default ChooseBuilding;
