import React, { useEffect, useRef, useState } from 'react';
import { useContract, useNetwork } from 'wagmi';
import ChooseRegionCard from './ChooseRegionCard';
import ChooseGameCard from './ChooseRegionCard';
import { get } from '../../../utils/Networks';
import ExplorerABI from '../../../chain/Explorer.json';
import RegionABI from '../../../chain/Region.json';
import ChooseRegion from './ChooseRegion';
import ChooseBuilding from './ChooseBuilding';
import LiveRegion from './LiveRegion';
import LiveBuilding from './LiveBuilding';

function Explore(props) {
  const [{ data: networkData, error: networkError, loading: networkLoading }, switchNetwork] = useNetwork();
  const explorerContract = useContract({
    // addressOrName: get(networkData.chain?.id).addresses.explorer,
    addressOrName: props.explorerAddress,
    // addressOrName: addresses.explorer,
    contractInterface: ExplorerABI.abi,
    signerOrProvider: props.signer,
  });

  const regionContract = useContract({
    addressOrName: get(networkData.chain?.id).addresses.region,
    // addressOrName: addresses.explorer,
    contractInterface: RegionABI.abi,
    signerOrProvider: props.signer,
  });

  const [regionNames, setRegionNames] = useState([]);
  const [activeRegion, setActiveRegion] = useState(null);
  const [liveAction, setLiveAction] = useState(null);
  const [buildingNames, setBuildingNames] = useState([]);
  const [actionSet, setActionSet] = useState([]);

  const [currentTime, setCurrentTime] = useState(0);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    loadData();
  }, []);

  async function setTime() {
    const curBlock = await props.signer.provider.getBlockNumber();
    const curTime = (await props.signer.provider.getBlock(curBlock)).timestamp;
    setCurrentTime(curTime);
  }

  async function loadData() {
    console.log('loading explorer data');
    console.log('exp' + explorerContract.addressOrName);
    const regionNames = await explorerContract.getMyRegionNames(props.id);
    setRegionNames(regionNames);
    console.log('regionNames', regionNames);

    try {
      setTime();

      const curRegion = await explorerContract.getMyActiveRegion(props.id);

      setActiveRegion(curRegion);
      const buildings = await explorerContract.getMyBuildings(props.id);
      setBuildingNames(buildings);
      console.log('buildings', buildings);
      let loadingActions = [];
      for (let i = 0; i < buildings.length; i++) {
        console.log('loading action: ' + buildings[i]);
        const currentActions = await explorerContract.getActions(props.id, buildings[i]);
        loadingActions.push(currentActions);
      }

      setActionSet(loadingActions);

      const action = await explorerContract.liveActions(props.id);
      setLiveAction(action);
      console.log('live action: ' + action);
    } catch (e) {
      if (e.message.includes('No Active Region')) {
        //no biggie
      } else {
        console.error(e, e.stack);
      }
      // console.error(e, e.stack);
      setActiveRegion(null);
    }
  }

  return (
    <section className="pt-20 pb-24  bg-gray-800">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          {activeRegion === null ? (
            <ChooseRegion explorerContract={explorerContract} id={props.id} regionNames={regionNames} setReload={props.setReload} />
          ) : (
            <LiveRegion currentTime={props.currentTime} activeRegion={activeRegion} castle={props.castle} />
          )}
          <div className="pb-5 border-b border-gray-500" />

          {liveAction !== null && liveAction[3] !== false ? (
            <LiveBuilding
              explorerContract={explorerContract}
              currentTime={props.currentTime}
              liveAction={liveAction}
              castle={props.castle}
            />
          ) : (
            <ChooseBuilding
              explorerContract={explorerContract}
              id={props.id}
              buildingNames={buildingNames}
              actionSet={actionSet}
              setReload={props.setReload}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default Explore;
