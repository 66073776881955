import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { chain, defaultL2Chains, Provider, useNetwork, WagmiProvider } from 'wagmi';
import { defaultChains } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { ethers, providers } from 'ethers';
import { AlchemyProvider } from '@ethersproject/providers';
import { networks } from './utils/Networks';

// import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
// import { WalletLinkConnector } from 'wagmi/connectors/walletLink';

// API key for Ethereum node
// Two popular services are Infura (infura.io) and Alchemy (alchemy.com)
const infuraId = process.env.INFURA_ID;
const alchemyId = process.env.NEXT_PUBLIC_ALCHEMY_ID;

// Chains for connectors to support
const chains = [chain.polygonMainnet, chain.polygonTestnetMumbai, chain.localhost];

// Set up connectors
const connectors = ({ chainId }) => {
  const rpcUrl = chains.find(x => x.id === chainId)?.rpcUrls?.[0] ?? chain.mainnet.rpcUrls[0];
  return [
    new InjectedConnector({
      chains: chains,
      options: {
        shimDisconnect: true,
      },
    }),
    // new WalletConnectConnector({
    //   options: {
    //     infuraId,
    //     qrcode: true,
    //   },
    // }),
    // new WalletLinkConnector({
    //   options: {
    //     appName: 'My wagmi app',
    //     jsonRpcUrl: `${rpcUrl}/${infuraId}`,
    //   },
    // }),
  ];
};

// const provider = ({ chainId }: ProviderConfig) =>
//   providers.getDefaultProvider(
//     isChainSupported(chainId) ? chainId : defaultChain.id,
//     {
//       alchemy: alchemyId,
//       etherscan: etherscanApiKey,
//       infura: infuraId,
//     },
//   )

const hardProvider = new AlchemyProvider('maticmum', alchemyId);

const provider = _chain => {
  let net;

  if (_chain?.chainId?.toString() === '31337') {
    return ethers.getDefaultProvider('http://127.0.0.1:8545/');
  }

  if (_chain?.chainId == null) {
    net = networks.find(x => x.id === chain.polygonTestnetMumbai.id);
  } else {
    net = networks.find(x => x.id === _chain.chainId);
  }

  if (net == null) {
    net = networks.find(x => x.id === chain.polygonTestnetMumbai.id);
  }

  // console.log('provider from: ', net);

  return new AlchemyProvider(net.name, net.key);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WagmiProvider autoConnect connectors={connectors} provider={provider}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </WagmiProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
