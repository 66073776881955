import React from 'react';
import { useNetwork } from 'wagmi';
import { chain } from 'wagmi';
import CastleCard from '../components/CastleCard';
import NotYet from '../components/NotYet';
import Token from '../components/wallet/Token';
import { isDev } from '../utils/Networks';
import circle from '../img/circle.svg';
import nft0 from '../img/nft0.png';
import HeldCastle from '../components/wallet/HeldCastle';
import { Link } from 'react-router-dom';

function Wallet(props) {
  console.log('loading profile');

  const [{ data: networkData, error: networkError, loading: networkLoading }, switchNetwork] = useNetwork();

  if (isDev(networkData.chain?.id)) {
    return (
      <div>
        <section className="relative py-20 2xl:py-40 bg-gray-900 overflow-hidden">
          <h2 className="mb-24 text-center text-5xl font-bold font-heading text-white">Supplies</h2>

          <img className="block absolute top-0 left-0 -ml-96 -mt-40" src={circle} alt="" />
          <img className="block absolute bottom-0 right-0 -mr-32 -mb-72" src={circle} alt="" />
          <div className="relative container px-4 mx-auto">
            <div>
              <div className="flex flex-wrap -m-6">
                {props.tokens.map((object, i) => (
                  <Token key={i} name={object.name} held={object.balance} description={object.description} />
                ))}

                {/* <Token
                  name="Hydra Tooth"
                  held="1"
                  description="If you can get close enough to a Hydra, it's easy enough to have as many teeth as you need."
                />
                <Token
                  name="Hydra Tooth"
                  held="1"
                  description="If you can get close enough to a Hydra, it's easy enough to have as many teeth as you need."
                />
                <Token
                  name="Hydra Tooth"
                  held="1"
                  description="If you can get close enough to a Hydra, it's easy enough to have as many teeth as you need."
                />
                <Token
                  name="Hydra Tooth"
                  held="1"
                  description="If you can get close enough to a Hydra, it's easy enough to have as many teeth as you need."
                />
                <Token
                  name="Hydra Tooth"
                  held="1"
                  description="If you can get close enough to a Hydra, it's easy enough to have as many teeth as you need."
                />
                <Token
                  name="Hydra Tooth"
                  held="1"
                  description="If you can get close enough to a Hydra, it's easy enough to have as many teeth as you need."
                /> */}
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20 2xl:py-40 bg-gray-800 overflow-hidden">
          <img className="absolute top-0 left-0 -mt-80 -ml-96" src="zospace-assets/lines/circle.svg" alt="" />
          <img className="absolute bottom-0 right-0 h-full -mb-40 -mr-96" src="zospace-assets/lines/circle.svg" alt="" />
          <div className="relative container px-4 mx-auto">
            <h2 className="mb-24 text-center text-5xl font-bold font-heading text-white">Castles</h2>
            <div className="flex flex-wrap justify-center -mx-5 mb-20">
              {props.castles.map((object, i) => (
                <Link key={i} className="w-full lg:w-1/3 px-5 mb-10 lg:mb-0 py-5" to={'/play/' + object.id}>
                  <HeldCastle castle={object} />
                </Link>
              ))}
            </div>
            <div className="text-center">
              <button className="inline-flex mr-2 items-center justify-center w-14 h-14 bg-blue-500 hover:bg-blue-600 rounded-full">
                <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.84708 12.115C7.05097 12.3207 7.05097 12.6512 6.84708 12.8554C6.64319 13.0596 6.31377 13.0603 6.10987 12.8554L0.152917 6.87493C-0.0509739 6.67074 -0.0509738 6.34026 0.152917 6.13456L6.10988 0.154119C6.31377 -0.0500693 6.64319 -0.0500693 6.84708 0.154119C7.05097 0.359063 7.05097 0.690301 6.84708 0.894489L1.4143 6.50512L6.84708 12.115Z"
                    fill="white"
                  ></path>
                </svg>
              </button>
              <button className="inline-flex items-center justify-center w-14 h-14 bg-blue-500 hover:bg-blue-600 rounded-full">
                <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.15292 0.901511C-0.0509709 0.696057 -0.0509709 0.365972 0.15292 0.16203C0.356811 -0.0419136 0.686232 -0.0426689 0.890123 0.16203L6.84708 6.13528C7.05097 6.33923 7.05097 6.66931 6.84708 6.87476L0.890121 12.848C0.686231 13.052 0.35681 13.052 0.152919 12.848C-0.050972 12.6433 -0.0509719 12.3125 0.152919 12.1085L5.5857 6.50465L0.15292 0.901511Z"
                    fill="white"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <NotYet
        title="Profiles are coming to the testnet first"
        text="When minting is live you'll be able to view your castles stats here.  In the meantime, you can try out minting on the Polygon Mumbai testnet!"
      ></NotYet>
    );
  }
}

export default Wallet;
