import { stringify } from 'postcss';
import { useAccount, useConnect, useNetwork } from 'wagmi';

export const ConnectWalletButton = () => {
  const [{ data: connectData, error: connectError }, connect] = useConnect();
  const [{ data: accountData }, disconnect] = useAccount({
    fetchEns: true,
  });
  const [{ data: networkData, error: networkError, loading: networkLoading }, switchNetwork] = useNetwork();

  function getButtonClass(unsupported) {
    let buttonClass = 'gap-2 p-4 border hover:border-white rounded-full';
    if (unsupported) {
      buttonClass = buttonClass.concat(' border-red-900 bg-red-400 text-black');
    } else {
      buttonClass = buttonClass.concat(' border-gray-200 text-white');
    }
    return buttonClass;
  }

  if (accountData) {
    const addr = accountData.address;
    return (
      <div>
        <div className="dropdown">
          <label tabIndex="0" className={getButtonClass(networkData.chain?.unsupported)}>
            {addr.slice(0, 6)} ... {addr.slice(addr.length - 4, addr.length)}
          </label>
          <ul tabIndex="0" className="dropdown-content menu p-2 shadow bg-base-100 rounded-box">
            {switchNetwork &&
              networkData.chains.map(x =>
                x.id === networkData.chain?.id ? null : (
                  <li key={x.id}>
                    <button key={x.id} onClick={() => switchNetwork(x.id)}>
                      Switch to {x.name}
                    </button>
                  </li>
                )
              )}
            <li>
              <a onClick={disconnect}>Disconnect</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <button className={getButtonClass(false)} onClick={() => connect(connectData.connectors[0])}>
      {connectError ? connectError.message : 'Connect Wallet'}
    </button>
  );
};
