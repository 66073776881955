import dayjs from 'dayjs';
import React, { useState } from 'react';

function RegionSelector(props) {
  const [activeTabIndex, setActiveTabIndex] = useState(-1);

  const regions = [
    {
      name: 'strange',
      description: 'This is a strange region',
    },
    {
      name: 'swamp',
      description: 'This is a swamp region',
    },
    {
      name: 'mountains',
      description: 'Tumultuous peaks and flash storms are common in the mountains.  ',
    },
    {
      name: 'mountain',
      description: 'Tumultuous peaks and flash storms are common in the mountains.  ',
    },
    {
      name: 'forest',
      description: 'This is a forest region',
    },
    {
      name: 'desert',
      description: 'This is a desert region',
    },

    {
      name: 'mirror',
      description: 'This is a mirror region',
    },
  ];

  function handleTabClick(index) {
    if (index !== activeTabIndex) {
      setActiveTabIndex(index);
    }
  }

  function getActive(index) {
    return index === activeTabIndex ? 'tab-active' : '';
  }

  function renderTabs() {
    return regions.map((region, index) => {
      if (props.regionNames.includes(region.name)) {
        return (
          <button
            key={index}
            className={'tab tab-lg ' + getActive(index)}
            onClick={() => {
              handleTabClick(index);
            }}
          >
            {region.name}
          </button>
        );
      }
      return <></>;
    });
  }

  function renderContent() {
    return regions.map((region, index) => {
      if (activeTabIndex === index) {
        return <p key={index}>{region.description}</p>;
      }
      return <> </>;
    });
  }

  async function selectRegion() {
    let index = props.regionNames.indexOf(regions[activeTabIndex].name);
    const tx = await props.explorerContract.chooseRegion(props.castle.id, index);

    const receipt = await tx.wait();
    props.setReload(true);
  }

  console.log('RegionSelector props: ', props.activeRegion);
  return (
    <>
      {props.activeRegion === null ? (
        <div>
          <p className="p-10">
            The ground below your castle is vast and diverse, yet covered in a hazy fog. From your vantage point in the skies above, you can
            only see landscape features of the regions on the surface.
          </p>
          <p>To begin your exploration, you must first decide upon a region to descend with your castle.</p>
          <div>
            <h2 className="card-title pt-12">Choose a Region to Explore</h2>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <div className="grid grid-cols-3 gap-4 tabs tabs-boxed">{renderTabs()}</div>
                <div className="p-10">{renderContent()}</div>
              </div>
            </div>
          </div>
          {activeTabIndex !== -1 ? (
            <button
              className="btn btn-primary m-10"
              onClick={() => {
                selectRegion();
              }}
            >
              Set your castle in the {regions[activeTabIndex].name} Region
            </button>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div>
          <p className="p-10">
            Your castle has found it's way to the surface, and is now located in the{' '}
            <span className="font-bold text-2xl"> {props.activeRegion}</span> region. With a clear view of your surroundings you are able to
            see structures and buildings in the area.
          </p>
          <p className="">Your castle will be in the {props.activeRegion} until</p>
          <p className="font-bold text-2xl">
            {dayjs()
              .second(props.castle.regionLockExpire - props.currentTime)
              .format('MMMM D, h:mm A, YYYY')}
          </p>
        </div>
      )}
    </>
  );
}

export default RegionSelector;
