import React, { useEffect, useRef, useState } from 'react';
import { useContract, useNetwork } from 'wagmi';
import { chain } from 'wagmi';
import card0 from '../img/nft0.png';
import ExplorerABI from '../chain/Explorer.json';
import RegionABI from '../chain/Region.json';

import { useAccount } from 'wagmi';
import NotYet from '../components/NotYet';
import { useParams } from 'react-router-dom';
import { BigNumber } from 'ethers';
import { get, isDev } from '../utils/Networks';
import RegionSelector from '../components/explorer/RegionSelector';
import CastleCard from '../components/CastleCard';
import BuildingSelector from '../components/explorer/BuildingSelector';

function Castle(props) {
  console.log('loading castle');
  let { id } = useParams();

  const castle = props.castles.find(castle => BigNumber.from(castle.id).toString() === BigNumber.from(id).toString());

  const [{ data: networkData, error: networkError, loading: networkLoading }, switchNetwork] = useNetwork();
  const [{ data: accountData }, disconnect] = useAccount({ fetchEns: true });
  const explorerContract = useContract({
    addressOrName: get(networkData.chain?.id).addresses.explorer,
    // addressOrName: addresses.explorer,
    contractInterface: ExplorerABI.abi,
    signerOrProvider: props.signer,
  });

  const regionContract = useContract({
    addressOrName: get(networkData.chain?.id).addresses.region,
    // addressOrName: addresses.explorer,
    contractInterface: RegionABI.abi,
    signerOrProvider: props.signer,
  });

  const [regionNames, setRegionNames] = useState([]);
  const [activeRegion, setActiveRegion] = useState(null);
  const [liveAction, setLiveAction] = useState(null);
  const [buildingNames, setBuildingNames] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    console.log('loading data');
    loadData();
  }, []);

  async function setTime() {
    const curBlock = await props.signer.provider.getBlockNumber();
    const curTime = (await props.signer.provider.getBlock(curBlock)).timestamp;
    setCurrentTime(curTime);
  }

  async function loadData() {
    const regionNames = await explorerContract.getMyRegionNames(id);
    setRegionNames(regionNames);

    try {
      setTime();

      const curRegion = await explorerContract.getMyActiveRegion(id);

      setActiveRegion(curRegion);
      const buildings = await explorerContract.getMyBuildings(id);
      setBuildingNames(buildings);

      const action = await explorerContract.liveActions(id);
      setLiveAction(action);
      console.log('live action: ' + action);
    } catch (e) {
      if (e.message.includes('No Active Region')) {
        //no biggie
      } else {
        console.error(e, e.stack);
      }
      // console.error(e, e.stack);
      setActiveRegion(null);
    }
  }

  let stats = [
    { name: 'Authority', value: castle.stats[0] },
    { name: 'Sustainability', value: castle.stats[1] },
    { name: 'Resiliency', value: castle.stats[2] },
    { name: 'Literacy', value: castle.stats[3] },
    { name: 'Creativity', value: castle.stats[4] },
    { name: 'Culture', value: castle.stats[5] },
  ];

  let skills = [
    { name: 'Astromancy', value: castle.skills[0] },
    { name: 'Horomancy', value: castle.skills[1] },
    { name: 'Geomancy', value: castle.skills[2] },
    { name: 'Technomancy', value: castle.skills[3] },
    { name: 'Lunacy', value: castle.skills[4] },
  ];

  if (isDev(networkData.chain?.id)) {
    return (
      // <div>
      <div className="mt-28 bg-base-200 ">
        <CastleCard castle={castle} />

        <div className="card-normal w-auto rounded-lg bg-primary-content shadow-xl m-20 p-6">
          <div className="tabs  place-content-center m-3">
            <a className="tab tab-bordered tab-active">Explore</a>
            <a className="tab tab-bordered">Party</a>
            <a className="tab tab-bordered">Crash</a>
          </div>
          <div className="card-body">
            <RegionSelector
              activeRegion={activeRegion}
              castle={castle}
              regionNames={regionNames}
              explorerContract={explorerContract}
              setReload={props.setReload}
              currentTime={currentTime}
            />

            {/* {activeRegion ? ( */}
            <BuildingSelector
              castle={castle}
              activeRegion={activeRegion}
              liveAction={liveAction}
              buildingNames={buildingNames}
              explorerContract={explorerContract}
              regionContract={regionContract}
              setReload={props.setReload}
              currentTime={currentTime}
            />
            {/* ) : (
              <></>
            )} */}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <NotYet
        title="Profiles are coming to the testnet first"
        text="When minting is live you'll be able to view your castles stats here.  In the meantime, you can try out minting on the Polygon Mumbai testnet!"
      ></NotYet>
    );
  }
}

export default Castle;
