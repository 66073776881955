import React from 'react';

function Logo(props) {
  return (
    <div width="120px">
      {props.show ? (
        <h1 id="title-small">
          two
          <br />
          <span>moons</span>
        </h1>
      ) : (
        <div />
      )}
    </div>
  );
}

export default Logo;
