import React from 'react';
import floatingmoons from '../../img/floating-moons.svg';

function HomeShoutout(props) {
  return (
    <div className="relative w-full lg:w-1/2 px-10 mb-10 lg:mb-20">
      {props.moons ? <img className=" absolute top-0 right-0 -mt-16" src={floatingmoons} alt="" /> : <> </>}
      <div className="flex flex-wrap -mx-4 -mb-4 md:mb-0 bg-gray-600 rounded-lg ">
        <div className="w-full md:w-1/3 px-4 mb-4 md:mb-0">
          <span className="flex my-10 justify-center items-center p-5 w-40 h-40 bg-blue-500 rounded-lg">
            <img src={props.image} alt="castle" className="rounded-xl" />
          </span>
        </div>
        <div className="w-full md:w-2/3 px-4 mb-4 md:mb-0">
          <h3 className="mt-12 mb-8 text-4xl font-bold text-white">{props.title}</h3>
          <p className="text-lg text-gray-200">{props.body}</p>
        </div>
      </div>
    </div>
  );
}

export default HomeShoutout;
