import React, { useEffect, useRef } from 'react';
import { chain, useContract, useNetwork, useSigner } from 'wagmi';
import SkyCastleABI from '../chain/SkyCastle.json';
import SkyCastleStatsABI from '../chain/SkyCastleStats.json';
import ExplorerABI from '../chain/Explorer.json';
import RegionABI from '../chain/Region.json';
import { get } from '../utils/Networks';
import testnetAddresses from '../chain/testnet-phase-one.json';

function State(props) {
  const [{ data: signer, error, loading }, getSigner] = useSigner();
  // const [{ data: networkData, error: networkError, loading: networkLoading }, switchNetwork] = useNetwork();
  // const [castleAddress, setCastleAddress] = React.useState();
  // const [statsAddress, setStatsAddress] = React.useState();
  const [network, setNetwork] = React.useState();

  const castleContract = useContract({
    addressOrName: props.castleAddress,
    contractInterface: SkyCastleABI.abi,
    signerOrProvider: signer,
  });

  const statsContract = useContract({
    addressOrName: props.statsAddress,
    contractInterface: SkyCastleStatsABI.abi,
    signerOrProvider: signer,
  });

  const explorerContract = useContract({
    addressOrName: props.explorerAddress,
    contractInterface: ExplorerABI.abi,
    signerOrProvider: signer,
  });

  const regionContract = useContract({
    addressOrName: props.regionAddress,
    contractInterface: RegionABI.abi,
    signerOrProvider: signer,
  });

  const tokenAbi = ['function balanceOf(address owner) view returns (uint balance)'];

  const glassShardContract = useContract({
    addressOrName: props.glassShardAddress,
    contractInterface: tokenAbi,
    signerOrProvider: signer,
  });

  const dragonToothContract = useContract({
    addressOrName: props.dragonToothAddress,
    contractInterface: tokenAbi,
    signerOrProvider: signer,
  });

  // setNetwork(props.network);
  // setNetwork('_');
  console.log('loading state for: ' + props.network?.chain?.id);

  useEffect(() => {
    console.log('state effect trigggered');
    // if (isFirstRender.current) {
    //   isFirstRender.current = false;
    //   return;
    // }
    setNetwork(props.network);

    if (signer !== undefined && props.network.chain?.id !== undefined) {
      loadData(signer, props.network.chain.id);
    }
  }, [signer, props.reload]);

  async function loadData(signer, chainid) {
    props.setLoaded(false);
    console.log('logged in... ' + (await signer.getAddress()) + ' on network: ' + props.network.chain.id);

    props.setSigner(signer);

    const balance = await castleContract.balanceOf(await signer.getAddress());

    props.setBalance(balance);
    await getHeldCastles(balance);

    props.setTotalSupply(await castleContract.totalSupply());
    props.setCost(await statsContract.getMintPrice());
    props.setLoaded(true);

    let tokens = [];
    tokens.push({
      name: 'glass shard',
      description: 'avoid stepping on it, good luck finding a use',
      balance: await glassShardContract.balanceOf(await signer.getAddress()),
    });
    tokens.push({
      name: 'hyrda tooth',
      description: 'If you can get close enough to a Hydra, its easy enough to have as many teeth as you need.',
      balance: await dragonToothContract.balanceOf(await signer.getAddress()),
    });
    props.setTokens(tokens);
  }

  async function getHeldCastles(balance) {
    let held = [];
    for (var i = 0; i < balance; i++) {
      var castle = {};

      castle.id = await castleContract.tokenOfOwnerByIndex(await signer.getAddress(), i);

      castle.stats = await statsContract.getStats(castle.id);

      castle.skills = await statsContract.getSkills(castle.id);

      castle.rarity = await statsContract.getRarity(castle.id);

      castle.metadata = await castleContract.tokenURI(castle.id);

      const regionlockedAt = await explorerContract.regionStartTime(castle.id);
      const regionLockDuration = await explorerContract.getRegionLock(castle.id);

      castle.regionLockExpire = +regionlockedAt + +regionLockDuration;
      console.log('regionLockExpire: ' + castle.regionLockExpire);

      const liveAction = await explorerContract.liveActions(castle.id);
      const actionLockedAt = liveAction[4];
      const action = await explorerContract.actionsRepository(liveAction[2]);
      const actionDuration = action[4];
      castle.actionLockExpire = +actionLockedAt + +actionDuration;
      console.log('actionLockExpire: ' + castle.actionLockExpire);

      held.push(castle);
    }
    props.setCastles(held);
  }

  return <></>;
}

export default State;
