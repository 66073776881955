import React from 'react';

import bluedoublecircle from '../img/blue-double-circle.svg';
import halfdoublecricle from '../img/half-double-circle.svg';

function NotYet(props) {
  return (
    <section className="relative py-20 2xl:py-40 bg-gray-800 overflow-hidden">
      <img className="absolute top-0 right-0 md:-mr-96 md:-mt-112" src="zospace-assets/lines/circle.svg" alt="" />
      <img className="absolute top-0 right-0 h-40 lg:h-80 lg:mr-64 lg:mt-24" src="zospace-assets/images/five-stars.svg" alt="" />
      /<img className="hidden lg:block absolute top-0 left-0 h-64 -ml-10" src={bluedoublecircle} alt="" />
      <img className="absolute bottom-0 left-0 h-80" src={halfdoublecricle} alt="" />
      <div className="relative container px-4 mx-auto">
        <div className="max-w-3xl mx-auto">
          <div className="max-w-xl">
            <a className="inline-block text-white text-xl font-bold" href="#">
              <img className="h-7" src="zospace-assets/logos/zospace-logo.svg" alt="" width="auto" />
            </a>
            <h2 className="mt-12 mb-14 text-5xl font-bold font-heading text-white">{props.title}</h2>
            <p className="mb-20 text-xl text-gray-200">{props.text}</p>
            {/* <a
              class="inline-block px-12 py-4 text-lg bg-blue-500 hover:bg-blue-600 text-white font-bold rounded-full transition duration-200"
              href="#"
            >
              Start again
            </a> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotYet;
