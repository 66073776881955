import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import { RiArrowRightUpLine } from 'react-icons/ri';
import { ConnectWalletButton } from './ConnectWalletButton';
import menudots from '../img/menu_dots.svg';

function Navbar(props) {
  const links = [
    { name: 'Wallet', path: '/wallet' },
    { name: 'Mint', path: '/mint' },
    { name: 'Play', path: '/play' },
  ];

  return (
    <div>
      <nav className="relative">
        <div className="flex py-8 px-4 lg:px-8 justify-between items-center">
          <div className="text-2xl text-white font-bold">
            <Link to="/">
              <Logo show={true} />
            </Link>
          </div>

          <div className="lg:hidden dropdown">
            <button className="p-2 navbar-burger ">
              <img className="w-10 h-10" src={menudots}></img>
            </button>
            <ul className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 justify-end">
              {links.map((link, i) => (
                <li key={i}>
                  <Link className="text-white font-bold text-lg" to={link.path}>
                    {link.name}
                  </Link>
                </li>
              ))}
              <li>
                <a className="text-white font-bold text-lg" target="_blank" rel="noopener noreferrer" href="https://docs.twomoons.xyz">
                  Whitepaper
                  <RiArrowRightUpLine className="p-0.5" style={{ display: 'inline', fontWeight: 'normal' }} />
                </a>
              </li>
            </ul>
          </div>
          <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
            <div className="hidden lg:block">
              <ul className="flex items-center text-white space-x-10">
                {links.map((link, i) => (
                  <React.Fragment key={i}>
                    <li>
                      <Link className="text-white font-bold text-lg" to={link.path}>
                        {link.name}
                      </Link>
                    </li>
                    <span>
                      <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2.5" cy="2.5" r="2.5" fill="#726B6B"></circle>
                      </svg>
                    </span>
                  </React.Fragment>
                ))}
                <li>
                  <a className="text-white font-bold text-lg" target="_blank" rel="noopener noreferrer" href="https://docs.twomoons.xyz">
                    Whitepaper
                    <RiArrowRightUpLine className="p-0.5" style={{ display: 'inline', fontWeight: 'normal' }} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div className="inline-block font-bold  rounded-full" href="#">
              <ConnectWalletButton />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
