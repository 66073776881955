import { chain } from 'wagmi';

import mainnetAddresses from '../chain/testnet-phase-one.json';
import testnetAddresses from '../chain/testnet-phase-one.json';
import localAddresses from '../chain/localhost-phase-one.json';
import { getNetwork } from '@ethersproject/networks';

const alchemyMainnetKey = 'H6fWtNHXiXw_0mtCHCt5ZbcM_S8hTP68';
const alchemyTestnetKey = 'H6fWtNHXiXw_0mtCHCt5ZbcM_S8hTP68';

export const networks = [
  {
    id: chain.polygonMainnet.id,
    name: 'matic',
    key: alchemyMainnetKey,
    addresses: mainnetAddresses,
  },
  {
    id: chain.polygonTestnetMumbai.id,
    name: 'maticmum',
    key: alchemyTestnetKey,
    addresses: testnetAddresses,
  },
  {
    id: '31337',
    name: 'localhost',
    key: alchemyTestnetKey,
    addresses: localAddresses,
  },
];

export function isDev(id) {
  return id?.toString() === '31337' || id === chain.polygonTestnetMumbai.id || id?.toString() === '1337';
}

export function get(id) {
  if (id === undefined) {
    return null;
  }

  if (id.toString() === '31337') {
    console.log('loading network: ' + networks[2].name);
    return networks[2];
  }
  const net = networks.find(x => x.id === id);
  if (net != null) {
    console.log('loading network: ' + net.name);
    return net;
  }

  return net;
}
